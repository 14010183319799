@import 'assets/website_theme/scss/variables';

@import 'leaflet/dist/leaflet.css';
@import 'leaflet.markercluster/dist/MarkerCluster.css';
@import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
@import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
@import 'leaflet-responsive-popup/leaflet.responsive.popup.css';

.map-control {
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
  background-color: white;
  border-radius: 4px;
}

#map-bread-crumb-container {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  z-index: 999;
}
#map-bread-crumb-container .breadcrumb li:last-child a {
  color: #000 !important;
}

.map-harbour-container {
  position: absolute;
  z-index: 999;
  right: 0;
}

.map-harbour-container .map-harbours-selected {
  margin-top: 11px;
  margin-right: 20px;
}

#search_map {
  width: 100%;
  height: 70vh;
  max-height: 600px;
}

/* Marker Harbour */
.map-div-icon-harbour {
  background-color: $primary-medium-dark;
  border-radius: 25px;
  box-shadow: 0 0 0 5px rgba(67, 108, 1, 0.69);
  z-index: 410 !important;
}
.map-div-icon-harbour:before {
  margin-left: 5.3px;
  font-family: "Font Awesome 5 Free";
  font-weight: 500;
  color: rgba(255, 255, 255, 0.66);
  font-size: 17px;
  /*content: "\f0c8";*/
  content: "\f0c8";
}
.map-div-icon-harbour-selected {
  background-color: $primary-medium-dark;
  border-radius: 25px;
  box-shadow: 0 0 0 5px rgba(67, 108, 1, 0.69);
  z-index: 410 !important;
}
.map-div-icon-harbour-selected:before {
  margin-left: 5.3px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: white;
  font-size: 17px;
  content: "\f14a";
}

/* Marker Region */
.map-div-icon-region {
  background-image: url("../../img/logo_white.svg");
  background-color: $primary-medium-dark;
  background-size: contain;
  border-radius: 2px;
  box-shadow: 0 0 0 3px rgba(142, 174, 91, 0.52);
  z-index: 400 !important;
}
.map-popup-region:before {
  border-bottom-color: $primary;
}

/* Marker Airport */
.map-div-icon-airport {
  padding-top: 3px;
  background-color: #ababab;
  background-size: contain;
  border-radius: 25px;
  box-shadow: 0 0 0 5px rgba(183, 183, 183, 0.55);
  z-index: 200 !important;
}
.map-div-icon-airport:before {
  margin-left: 5px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: white;
  font-size: 13px;
  content: "\f5af";
}

/* Marker Cluster */
.leaflet-marker-icon.marker-cluster {
  z-index: 410 !important;
}

.leaflet-tooltip {
  padding: 1px 6px 1px 6px !important;
}

.leaflet-popup-content {
  min-width: 273px;
}